#nprogress {
    pointer-events: none;
}


/* Remove these to get rid of the spinner */

#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.splash-screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.splash-screen .loading-dot {
    font-size: 100px;
    margin-top: -80px;
    position: relative;
    animation: ease-in-out infinite alternate;
    animation-name: run;
    animation-duration: 1.2s;
}

@keyframes run {
    0% {
        left: -90px;
        color: #eee;
    }
    50% {
        color: #666;
    }
    100% {
        left: 90px;
        color: #eee;
    }
}